<template>
  <div>
    <div class="mt-4" v-if="selectedCar === null">
      <div class="car-container" v-if="this.showCarModels">
        <div class="areaheader">
          <h1>{{ this.trans[selectedLang]["FINANCE CALCULATOR"] }}</h1>
          <p class="txtMuted">
            {{
              this.trans[selectedLang][
                "Simply select your model and desired grade, set the parameters to your preference and get an estimated monthly payment instantly."
              ]
            }}
          </p>
        </div>

        <ul class="carList">
          <li v-for="(car, index) in cars" :key="index">
            <!-- {{index  = (index === 'T33' ? 'T32' : index ) }} -->
            <div v-if="configuredCar[index]" class="car-space">
              <div @click="selectCar(index)">
                <img :src="configuredCar[index].image" class="car-img" alt="" />
                <h5
                  class="heading text-start"
                  :class="{ arType: this.selectedLang == knowArType }"
                >
                  {{
                    this.selectedLang != "en"
                      ? configuredCar[index].ar_name
                      : configuredCar[index].display
                  }}
                </h5>
                <div
                  class="priceBox"
                  :class="{ arType: this.selectedLang == knowArType }"
                >
                  <label class="text-start"
                    >{{ this.trans[selectedLang]["Starting price"] }}*</label
                  >
                  <div
                    class="priceIn"
                    :class="this.selectedLang == 'en' ? 'flex' : ''"
                  >
                    <span class="text-end">
                      {{ Number(filterPrice(car.version)).toLocaleString() }}
                    </span>
                    <span class="currency-title">
                      {{ this.trans[selectedLang]["CURRENCY"] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <p class="car-discleamer">
          * {{ this.trans[selectedLang]["DISCLAIMER"] }}.
        </p>
      </div>
    </div>

    <div class="car-detail-section" v-if="this.checkCarAvaiable">
      <div class="container">
        <div class="headPart">
          <div class="row">
            <div class="col-12 text-right-btn">
              <button @click="goHome" class="backToHome">
                {{ this.trans[selectedLang]["SELECT A DIFFERENT MODEL"] }}
              </button>
              <!-- <button @click="goHome" class="backToHome"><span>Back To Home</span><img src="@/assets/home.png" /></button> -->
            </div>
            <div class="col-md-3 text-center" v-if="!showCarModels">
              <button @click="checkCarModel" class="btn2">
                {{ this.trans[selectedLang]["SELECT A DIFFERENT MODEL"] }}
              </button>
            </div>
            <div class="row coumnWrapper">
              <div class="col-md-3">
                <div class="gradeMinfo">
                  <div class="detail">
                    <h1 class="car-grade-">
                      {{ this.specificCarGrade.version_label }}
                    </h1>
                    <div class="custom-selectedValue">
                      <span class="span">
                        {{
                          Number(this.specificCarGrade.price).toLocaleString()
                        }}
                        <i>{{ this.trans[selectedLang]["CURRENCY"] }}</i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="rightDetail">
                  <img
                    :src="this.specificCarGrade.image.split('.jpg')[0] + '.jpg'"
                  />
                </div>
              </div>
              <div class="col-md-3 rightDetail">
                <div class="gradeMinfo">
                  <div
                    class="dropdown"
                    :class="{ arType: this.selectedLang == knowArType }"
                  >
                    <button
                      class="dropdownBtnCusomt dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        {{
                          !selectedGradeDrop
                            ? this.trans[selectedLang]["SELECT A GRADE"]
                            : this.selectedGradeDrop
                        }}
                      </span>
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        v-for="(specificCar, index) in specificCarVersion"
                        :key="index"
                      >
                        <div
                          v-if="specificCar.active != '0' && specificCar.price"
                        >
                          <button
                            class="dropdown-item"
                            type="button"
                            @click="
                              selectCarGrade(
                                index,
                                specificCar.Version_Label,
                                '1',
                                '2'
                              )
                            "
                          >
                            {{ specificCar.Version_Label }}
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="descriptionBox">
          <div class="row borderBottom align-items-normal-sm">
            <div class="col-7 col-md-6">
              <h3 class="main-title">
                {{ this.trans[selectedLang]["Down Payment"] }}
                {{ this.model.downpaymentRange }}%
              </h3>
            </div>
            <div class="col-5 col-md-6 text-end">
              <h5 class="pricing-text">
                {{
                  Number(
                    parseFloat(this.downpayment.totalAmount).toFixed(2)
                  ).toLocaleString()
                }}
                <span>{{ this.trans[selectedLang]["CURRENCY"] }}</span>
              </h5>
              <div class="rangerSlider float-end">
                <input
                  type="range"
                  :min="this.downpayment.min"
                  :max="this.downpayment.max"
                  @change="downPaymentRangeSlider()"
                  v-model="model.downpaymentRange"
                  class="form-range"
                  id="customRange1"
                />
              </div>
            </div>
          </div>

          <div class="row borderBottom">
            <div class="col-7 col-md-6">
              <h3 class="main-title">
                {{ this.trans[selectedLang]["TENURE"] }}
              </h3>
            </div>
            <div class="col-5 col-md-6 text-end">
              <h5 class="pricing-text">
                {{ this.model.tenureRange }}
                {{ this.trans[selectedLang]["Months"] }}
              </h5>
              <div class="rangerSlider float-end">
                <input
                  type="range"
                  class="form-range"
                  @change="tenureRangeSlider()"
                  step="12"
                  v-model="model.tenureRange"
                  :min="this.year_tenure_range.min"
                  :max="this.year_tenure_range.max"
                  id="customRange2"
                />
              </div>
            </div>
          </div>

          <div class="row borderBottom">
            <div class="col-7 col-md-6">
              <h3 class="main-title">
                {{ this.trans[selectedLang]["INSURANCE AMOUNT"] }}
              </h3>
            </div>
            <div class="col-5 col-md-6 text-end">
              <h5 class="pricing-text">
                {{
                  Number(
                    parseFloat(this.insurance.totalAmount).toFixed(2)
                  ).toLocaleString()
                }}
                <span>{{ this.trans[selectedLang]["CURRENCY"] }}</span>
              </h5>
            </div>
          </div>
          <div class="row borderBottom">
            <div class="col-7 col-md-6">
              <h3 class="main-title">
                {{ this.trans[selectedLang]["FINANCE AMOUNT"] }}
              </h3>
            </div>
            <div class="col-5 col-md-6 text-end">
              <h5 class="pricing-text">
                {{
                  Number(
                    parseFloat(this.financeAmount).toFixed(2)
                  ).toLocaleString()
                }}
                <span>{{ this.trans[selectedLang]["CURRENCY"] }}</span>
              </h5>
            </div>
          </div>
          <div class="row borderBottom border-bottom-none">
            <div class="col-7 col-md-6">
              <h3 class="main-title">
                {{ this.trans[selectedLang]["PROFIT RATE"] }}
              </h3>
            </div>
            <div class="col-5 col-md-6 text-end">
              <h5 class="pricing-text">
                {{
                  Number(
                    parseFloat(this.bankProfit).toFixed(2)
                  ).toLocaleString()
                }}
                <span>{{ this.trans[selectedLang]["CURRENCY"] }}</span>
              </h5>
            </div>
          </div>

          <div class="row">
            <div class="col-7 col-md-6">
              <h4 class="emi-heading">
                <span class="emi-titles"
                  >{{ this.trans[selectedLang]["MONTHLY PAYMENT"] }}*</span
                >
              </h4>
            </div>
            <div class="col-5 col-md-6 text-end">
              <h4 class="emi-heading">
                <span
                  class="emi-prices"
                  :class="this.selectedLang == 'en' ? 'flex' : ''"
                >
                  {{ Number(parseFloat(this.emi).toFixed(2)).toLocaleString() }}
                  <span> {{ this.trans[selectedLang]["CURRENCY"] }} </span>
                </span>
              </h4>
            </div>
          </div>

          <div class="mt-4">
            <!-- <a
              href="javascript:void(0)"
              @click="sendFinanceButtonClick"
              class="btn btn-nissan"
            >
              {{ this.trans[selectedLang]["GET A FINANCE QUOTE"] }}
            </a> -->
            <p class="disclaimer mt-2">
              * {{ this.trans[selectedLang]["TERMS AND CONDITION"] }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 pt-5"></div>
  </div>
</template>

<script>
import translatedLang from "../../json/translations.json";
import { adobeAnalyticCode } from "../adobeAnalyticsNew.js";

export default {
  name: "FinanceCalculator",
  props: {
    newcars: Object,
    vehiclerates: Object,
    carConfiguration: Object,
  },
  components: {},
  mounted() {
  },
  created() {
    this.cars = this.newcars.data.cars;
    this.carRates = this.vehiclerates;
    this.configuredCar = this.carConfiguration.cars_config;

    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);

    if (params.get("model") !== undefined) {
      // console.log(params.get("lang") ?? 'en');
      this.selectedLang = params.get("lang") ?? "en";
      if (this.selectedLang != "en" && this.selectedLang != "ar") {
        this.selectedLang = "en";
      }

      if (params.get("model") !== undefined) {
        this.checkCarAvaiable == true;
        this.selectCar(params.get("model"));
        //console.log(params.get("grade"));
        if (params.get("grade")) {
          let vehicleId = params.get("model");
          this.specificCarGrade.vehicle_id = vehicleId;

          this.specificCarGrade.model = this.configuredCar[vehicleId].display;

          this.checkCarAvaiable = true;
          var specificCarData = this.cars[vehicleId];

          this.specificCarVersion = specificCarData["version"];

          let gradeid = params.get("grade");

          let grade = gradeid.replace(/ /g, "_");

          // var specificCarGradeId = grade;
          //console.log(grade);
          // var specificCarGradeId = grade

          let realID = grade;

          for (const item in specificCarData.version) {
            // console.log(item, "name");.

            if (
              specificCarData.version[item].Version_Label.toUpperCase() ==
              gradeid.toUpperCase()
            ) {
              realID = item;
            }
          }

          setTimeout(() => {
            this.selectCarGrade(realID, gradeid, "1", "2");
          }, 100);

          // selectCarGrade(index, specificCar.Version_Label, '1', '2')
        }
      }
    }

    // Setting up the min and max percentage of Downpayment & Bank Profit Rate.
    this.downpayment.min = parseFloat(this.carRates.downpayment_percentage.min);
    this.downpayment.max = parseFloat(this.carRates.downpayment_percentage.max);

    this.insurance.percentage = parseFloat(this.carRates.insurance_rate);
    this.insurance.tax_rate = parseFloat(
      this.carRates.insurance_amount_tax_rate
    );
    this.insurance.minimum_amount = parseFloat(
      this.carRates.minimum_insurance_amount
    );

    this.year_tenure_range.min = parseInt(this.carRates.year_tenure_range.min);
    this.year_tenure_range.max = parseInt(this.carRates.year_tenure_range.max);
    this.model.tenureRange = parseInt(this.carRates.year_tenure_range.max);
    // End - Setting up the min and max percentage of Downpayment & Bank Profit Rate.

    var allData = {};
    adobeAnalyticCode(allData);
  },
  computed: {},
  watch: {
    "model.downpaymentRange": function () {
      this.downPaymentRangeSlider();
    },
    "model.tenureRange": function () {
      this.tenureRangeSlider();
    },
  },
  data() {
    return {
      knowArType: "ar",
      selectedGradeDrop: null,
      selectedCar: null,
      tabActiveStatus: {
        all: true,
        passenger: false,
        suvs: false,
        sports: false,
        commercial: false,
      },
      showCarModels: true,
      trans: translatedLang,
      selectedLang: null,
      carRates: {},
      cars: {},
      configuredCar: {},
      checkCarAvaiable: false,
      specificCarVersion: {},
      specificCarGrade: {
        carCategory: null,
        model: null,
        vehicle_id: null,
        key_index: null,
        version_label: null,
        price: "No Price",
        image: null,
      },
      model: {
        downpaymentRange: null,
        tenureRange: null,
      },
      insurance: {
        percentage: null,
        tax_rate: null,
        minimum_amount: null,
        totalAmount: null,
      },
      downpayment: {
        min: null,
        max: null,
        totalAmount: null,
      },
      financeAmount: 0,
      bankProfit: 0,
      year_tenure_range: {
        min: null,
        max: null,
      },
      emi: 0,
      gradeCheckAdobe: 0,
    };
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkCarModel() {
      var mobile = this.isMobile();
      if (mobile) {
        this.showCarModels = true;
      }
    },
    goHome() {
      this.checkCarAvaiable = false;
      this.selectedCar = null;
      window.location.href = "?lang=" + this.selectedLang;
    },
    filterPrice(object) {
      for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
          const element = object[key];
          if (element.price && element.active == 1) return element.price;
        }
      }
      return 0;
    },
    sendFinanceButtonClick() {
      var allData = {
        category: this.specificCarGrade.carCategory, // categories = PASSENGER,SUV,LCV
        model: this.specificCarGrade.model, // model = MAXIMA, ALTIMA
        version_name: this.specificCarGrade.version_label, // grade_or_version = MAXIMA S
        vehicleid: this.specificCarGrade.vehicle_id, // A36
        language: this.selectedLang, // en or ar
        button_name: "GET A FINANCE QUOTE",
        new_downPayment: Number(
          parseFloat(this.downpayment.totalAmount).toFixed(2)
        ).toLocaleString(),
        new_insurance: Number(
          parseFloat(this.insurance.totalAmount).toFixed(2)
        ).toLocaleString(),
        new_profit: Number(
          parseFloat(this.bankProfit).toFixed(2)
        ).toLocaleString(),
        new_year: Number(
          parseFloat(this.model.tenureRange).toFixed(2)
        ).toLocaleString(),
        new_monthlyPayment: this.emi,
        new_selectedCarPrice: Number(
          parseFloat(this.specificCarGrade.price).toFixed(2)
        ).toLocaleString(),
      };
      adobeAnalyticCode(allData);
      // window.location.href = "https://en-ae-az.dark.prod.heliosnissan.net/services/finance-request.html";

      window.open(
        "https://" +
          this.selectedLang +
          ".nissan-abudhabi.com/services/finance-request.html?modelName=" +
          this.specificCarGrade.model +
          "&currency=" +
          this.trans[this.selectedLang]["CURRENCY"] + // A3
          "&model=" +
          this.specificCarGrade.vehicle_id + // A3
          "&grade=" +
          this.specificCarGrade.version_label +
          "&dp=" +
          Math.round(this.downpayment.totalAmount) +
          "&insurance=" +
          Math.round(this.insurance.totalAmount) +
          "&price=" +
          Math.round(this.specificCarGrade.price) +
          "&rate=" +
          Math.round(this.bankProfit) +
          "&tenure=" +
          this.model.tenureRange +
          "&emi=" +
          Math.round(this.emi) +
          "&months=yes",
        "_parent" // <- This is what makes it open in a new window.
      );
    },
    downPaymentRangeSlider() {
      this.downpayment.totalAmount =
        ((parseFloat(this.specificCarGrade.price) +
          parseFloat(this.insurance.totalAmount)) *
          this.model.downpaymentRange) /
        100;
      this.financeAmountCalculation();
      this.bankProfitCalculation();
      this.tenureRangeSlider();
    },
    selectCar(vehicleId) {
      this.selectedCar = vehicleId;
      var mobile = this.isMobile();
      if (mobile) {
        //this.showCarModels = false;
      }

      if (vehicleId) {
        this.specificCarGrade.vehicle_id = vehicleId;

        this.specificCarGrade.model = this.configuredCar[vehicleId].display;

        this.checkCarAvaiable = true;
        const allCars = this.cars;
        var specificCarData = allCars[vehicleId];

        this.specificCarVersion = specificCarData["version"];

        for (const item in specificCarData.version) {
          // console.log(item);
          if (specificCarData.version[item].active != 0) {
            var specificCarGradeId = item;
            break;
          }
        }
        this.selectCarGrade(specificCarGradeId, "", "1", "0");
      }
    },
    selectCarGrade(
      specificCarGradeId,
      item,
      adobeAnaStatus,
      sendDropdownAdobeGrade
    ) {
      this.selectedGradeDrop = item;

      //console.table(specificCarGradeId, item, "--------");
      this.model.downpaymentRange = this.downpayment.min;
      this.specificCarGrade.key_index = specificCarGradeId;

      const specificCarGradeData = this.specificCarVersion;
      this.specificCarGrade.version_label =
        specificCarGradeData[specificCarGradeId].Version_Label;
      this.specificCarGrade.price =
        specificCarGradeData[specificCarGradeId].price;
      this.specificCarGrade.image =
        specificCarGradeData[specificCarGradeId].image;

      if (adobeAnaStatus == "1") {
        if (sendDropdownAdobeGrade == "2") {
          this.gradeCheckAdobe = 1;
        }
        this.adobeAnalyticsSendData(this.specificCarGrade.vehicle_id);
      }

      //--- Start - Insurance Calculation with Tax Rate
      var insurance_amount;
      if (
        this.carRates.COMMERCIAL_VEHICLES.includes(
          this.specificCarGrade.vehicle_id
        )
      ) {
        // console.log(this.carRates.commercial_insurance_rate, "Commercial Vehicle Insurance Rate");
        insurance_amount = parseFloat(
          (this.specificCarGrade.price *
            this.carRates.commercial_insurance_rate) /
            100
        );
      } else {
        insurance_amount = parseFloat(
          (this.specificCarGrade.price * this.insurance.percentage) / 100
        );
      }

      if (insurance_amount) {
        var insurance_tax_amount = parseFloat(
          (insurance_amount * this.insurance.tax_rate) / 100
        );
        this.insurance.totalAmount =
          parseFloat(insurance_amount) + parseFloat(insurance_tax_amount);
      }

      // Set Min Insurance Amt if less than the given minimum_insurance_amount
      if (this.insurance.totalAmount <= this.insurance.minimum_amount) {
        this.insurance.totalAmount = this.insurance.minimum_amount;
      }
      //--- End - Insurance Calculation with Tax Rate

      this.downPaymentRangeSlider();
      this.financeAmountCalculation();
      this.bankProfitCalculation();
      this.tenureRangeSlider();
    },
    financeAmountCalculation() {
      this.financeAmount =
        parseFloat(this.specificCarGrade.price) +
        parseFloat(this.insurance.totalAmount) -
        this.downpayment.totalAmount;
    },
    bankProfitCalculation() {
      if (
        this.carRates.COMMERCIAL_VEHICLES.includes(
          this.specificCarGrade.vehicle_id
        )
      ) {
        // console.log(this.carRates.commercial_bank_rate, "Commercial Vehicle Bank Rate");
        this.bankProfit =
          ((this.financeAmount *
            parseFloat(this.carRates.commercial_bank_rate)) /
            100) *
          (this.model.tenureRange / 12);
      } else {
        this.bankProfit =
          ((this.financeAmount * parseFloat(this.carRates.bank_profit_rate)) /
            100) *
          (this.model.tenureRange / 12);
      }
    },
    tenureRangeSlider() {
      this.bankProfitCalculation();
      // this.emi = (parseFloat(this.bankProfit) + parseFloat(this.financeAmount)) / (this.model.tenureRange * 12);
      this.emi =
        (parseFloat(this.bankProfit) + parseFloat(this.financeAmount)) /
        this.model.tenureRange;
    },
    adobeAnalyticsSendData() {
      var carCategory = "";
      if (this.carRates.PASSENGER.includes(this.specificCarGrade.vehicle_id)) {
        carCategory = "PASSENGER CAR";
      }
      if (this.carRates.SUV.includes(this.specificCarGrade.vehicle_id)) {
        carCategory = "SUVS & CROSSOVERS";
      }
      if (this.carRates.SPORTS.includes(this.specificCarGrade.vehicle_id)) {
        carCategory = "SPORTS CARS";
      }
      if (
        this.carRates.COMMERCIAL_VEHICLES.includes(
          this.specificCarGrade.vehicle_id
        )
      ) {
        carCategory = "COMMERCIAL VEHICLES";
      }

      this.specificCarGrade.carCategory = carCategory;
      var allData = {
        category: this.specificCarGrade.carCategory,
        version_name: this.gradeCheckAdobe
          ? this.specificCarGrade.version_label
          : null, // MAXIMA SV
        model: this.specificCarGrade.model.toUpperCase(), // MAXIMA
        vehicleid: this.specificCarGrade.vehicle_id, //eg: A36
        language: this.selectedLang,
      };
      adobeAnalyticCode(allData);
    },
  },
};
</script>

